<template>
  <div class="tab-bar">
    <div class="tab-bar-fixed h5w">
      <div class="item"
      v-for="(item, i) in orgInfo.h5AppList" :key="'h5AppList2' + i"
      v-if="item.type == 2">
        <a :href="item.targetUrl" v-if="isHttp(item.targetUrl)">
          <div class="icon-box">
            <i :style="
            `background-image:url(${requestImgUrl(
              url === item.targetUrl ? item.iconSelect : item.iconSelectUn
            )});`
          "></i>
          </div>
          <p :style="
            `color: ${
              url === item.targetUrl ? item.activeCss : item.unActiveCss
            }`
          ">{{ item.name }}</p>
        </a>


        <router-link :data-url="item.targetUrl" :to="item.targetUrl" v-else>
          <div class="icon-box">
            <i :style="
            `background-image:url(${requestImgUrl(
              url === item.targetUrl ? item.iconSelect : item.iconSelectUn
            )});`
          "></i>
          <mt-badge v-if="item.targetUrl == '/shopping/car' && goodsNum > 0" class="badge" color="#eb3030" size="small">{{goodsNum}}</mt-badge>
          </div>
          <p :style="
            `color: ${
              url === item.targetUrl ? item.activeCss : item.unActiveCss
            }`
          ">{{ item.name }}</p>
        </router-link>
      </div>
      
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { requestImgUrl, countCartNum } from "@/utils/common";
import { isHttp } from "@/utils/utils";
export default {
  data() {
    this.requestImgUrl = (url, privates) => requestImgUrl(url, privates);
    this.isHttp = (v) => isHttp(v);
    return {
      url: '',
      uid: localStorage.getItem('uid'),
    }
  },
  mounted() {
    this.url = this.$route.path;
    this.getCommodityCount({
      uid: this.uid
    }).then((res)=> {
      this.goodsNumMuta(res.data.quantity)
    })
  },
  methods: {
    ...mapActions("shopping", ["getCommodityCount",]),
    ...mapMutations(["goodsNumMuta"])
  },
  computed: {
    ...mapState(["orgInfo", "goodsNum"]),
  },
}
</script>

<style lang="less" scoped>
  .tab-bar{
    height: 1.1rem;
    position: relative;
    z-index: 9999;
    .tab-bar-fixed{
      position: fixed;
      display: flex;
      align-items: center;
      bottom: 0;
      width: 100%;
      height: 1.1rem;
      background: #fff;
      box-shadow: 0 0 .1rem #f1f1f1;
      .router-link-active{
        color: #ff7241;
      }
      .icon-box{
        width: .5rem;
        height: .5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        position: relative;
        i{
          width: 100%;
          height: .4rem;
          background-size: auto 100%;
          background-repeat: no-repeat;
          background-position: center;
        }
        .badge{
          position: absolute;
          top: -.05rem;
          right: 0;
          font-size: .24rem;
          transform: translateX(40%);
        }
      }
      .item{
        flex: 1;
        text-align: center;
        p{
          font-size: .26rem;
          color: #a7a7a7;
        }
      }
    }
  }
</style>